import React, { useState } from "react";
import Modal from "./Modal"; // Import the Modal component
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleCatalogClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setShowModal(true); // Show the modal when Catalog is clicked
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <i className="fa-brands fa-product-hunt"></i>remier
        </div>
        <div className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <a href="#home" smooth={true} duration={1000} onClick={closeMenu}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" smooth={true} duration={1000} onClick={closeMenu}>
                About
              </a>
            </li>
            <li>
              <a href="#categories" smooth={true} duration={1000} onClick={closeMenu}>
                Services
              </a>
            </li>
            <li>
              <a href="#contact" smooth={true} duration={1000} onClick={closeMenu}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="catalog-button">
          <a href="#catalog" onClick={handleCatalogClick}>
            View Catalog
          </a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>

      {/* Modal component */}
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </nav>
  );
};

export default Navbar;
