import React from "react";
import "./ProductCategories.css"; // Importing styles

const ProductCategories = () => {
  // Updated categories with name, image, and description
  const categories = [
    {
      name: "Electronics",
      image:
        "https://images.pexels.com/photos/163100/circuit-circuit-board-resistor-computer-163100.jpeg?cs=srgb&dl=pexels-pixabay-163100.jpg&fm=jpg",
      description:
        "From cutting-edge gadgets to essential home and office devices, we connect you with the latest, most reliable electronics tailored to your market needs."
    },
    {
      name: "Luxury Goods",
      image:
        "https://cdn.open-pr.com/T/1/T116842541_g.jpg",
      description:
        "Elevate your offerings with exclusive, high-end products that reflect sophistication, elegance, and unparalleled quality."
    },
    {
      name: "Textiles and Apparel",
      image:
        "https://v2.primuspartners.in/docs/images/blog_images/full_VCEgW3O8ss4My0R9IF5x_1706706097436.jpg",
      description:
        "We specialize in sourcing premium fabrics and stylish clothing, ensuring durability and modern designs that resonate with your customers."
    },
    {
      name: "Automotive Supplies",
      image:
        "https://www.supplychainbrain.com/ext/resources/2023/05/25/CAR-MANUFACTURING-iStock--Traimak_Ivan--1069360792.jpg?t=1685036316&width=1080",
      description:
        "Access a comprehensive selection of auto parts and accessories to keep your business on the road to success."
    },
    {
      name: "Industrial Machinery",
      image:
        "https://media.istockphoto.com/id/510162486/photo/production-line-of-plastic-industry.jpg?s=612x612&w=0&k=20&c=n5y1V3eYDO7mBf1PR7Q-zARjqyxkWD4eKpe9UhKD6IM=",
      description:
        "Streamline operations with top-grade industrial equipment and machinery designed for efficiency and longevity."
    },
    {
      name: "Furniture and Home Decor",
      image:
        "https://media.istockphoto.com/id/1034261806/photo/modern-scandinavian-living-room-interior-3d-render.jpg?s=612x612&w=0&k=20&c=_j1omZD7JhpcuiPrHqRGOD7-KWWlvEQNIPFjqjO4E9E=",
      description:
        "Discover elegant furniture and decor that merge functionality with aesthetic appeal to enhance living and workspace environments."
    },
    {
      name: "Medical Supplies",
      image:
        "https://media.istockphoto.com/id/522549410/photo/medical-equipment-background.jpg?s=612x612&w=0&k=20&c=TgXoa_Psm2nBKLSvb31Wbp9tn0fAOvIpoDwHe2Wb5Ms=",
      description:
        "Trust us to deliver high-quality medical equipment and consumables that meet international standards of safety and reliability."
    },
    {
      name: "Beauty and Personal Care Products",
      image:
        "https://orchidlifesciences.com/wp-content/uploads/2024/06/01-14-01-1024x704.jpg",
      description:
        "From skincare essentials to beauty tools, we source products that cater to the ever-growing demand in the personal care market."
    },
    {
      name: "Food and Beverage",
      image:
        "https://www.pierrine-consulting.com/wp-content/uploads/2023/10/food-and-beverage.webp",
      description:
        "Secure a steady supply of quality food products and beverages that align with global trends and local preferences."
    },
    {
      name: "Eco-Friendly Products",
      image:
        "https://media.istockphoto.com/id/1426974319/photo/the-concept-of-zero-waste-and-recycling-top-view-of-eco-friendly-items-and-paper-packaging.jpg?s=612x612&w=0&k=20&c=tqBQygQkXpWG8qRAghoG7tWmH1xEfDHgmY6ESoH5xPk=",
      description:
        "Sustainability is at the core of our eco-friendly sourcing solutions, offering products that support greener living and responsible consumption."
    }
  ];

  return (
    <section className="product-categories" id="categories">
      <h2>Our Sourcing Services</h2>
      <div className="categories-grid">
        {categories.map((category, index) => (
          <div key={index} className="category-card">
            <img src={category.image} alt={category.name} />
            <div className="category-details">
              <h3>{category.name}</h3>
              <p>{category.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductCategories;
