// src/components/Catalog.js
import React, { useState } from "react"; 
import "./Catalog.css"; // Importing styles for this section
import Modal from "./Modal";

const Catalog = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    const closeMenu = () => {
      setIsMenuOpen(false);
    };
  
    const handleCatalogClick = (e) => {
      e.preventDefault(); // Prevent default anchor behavior
      setShowModal(true); // Show the modal when Catalog is clicked
    };

  return (
    <section className="catalog-section" id="catalog">
      <div className="catalog-container">
        <div className="catalog-text">
          <h2>Our Product Catalog</h2>
          <p>
            Our catalog offers a wide range of high-quality products sourced from
            around the world. Whether you're looking for cutting-edge electronics,
            stylish apparel, or luxury goods, our catalog has it all. We are
            committed to bringing you the best products to meet your business needs.
          </p>
          <p>
            Browse through our extensive catalog, which includes everything from
            industrial machinery to eco-friendly products. We provide solutions for
            businesses of all sizes and industries.
          </p>
          <div className="catalog-button">
          <a href="#catalog" onClick={handleCatalogClick}>
            View and Download Catalog
          </a>
        </div>
        </div>
      </div>

      {/* Modal component */}
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </section>
  );
};

export default Catalog;
