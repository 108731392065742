// src/components/Carousel.js
import React from "react";
import Slider from "react-slick"; // Importing react-slick
import "./Carousel.css"; // Import custom styles

const productSourcingSections = [
  {
    title: "Supplier Identification",
    description:
      "We connect you with reliable manufacturers and suppliers tailored to your product needs, ensuring quality and competitive pricing.",
      imgSrc: "https://www.infosysbpm.com/content/dam/infosys-bpm/en/sitecollectionimages/22/paving-the-path-to-improvement-with-automation.jpg",
  },
  {
    title: "Factory Audits",
    imgSrc: "https://flowlens.com/wp-content/uploads/2016/02/multiple-supplier-management-1024x685.jpg",
    description:
      "Our team conducts thorough inspections of supplier facilities to verify quality standards, production capacity, and ethical practices.",
  },
  {
    title: "Product Prototyping",
    imgSrc: "https://ventrify.ca/wp-content/uploads/2020/12/Prototyping-JPGLow.jpg",
    description:
      "Collaborate with our sourcing experts to develop and refine product prototypes before mass production.",
  },
  {
    title: "Raw Material Sourcing",
    imgSrc: "https://c8.alamy.com/comp/R67PKC/textile-factory-R67PKC.jpg",
    description:
      "We help you find the best sources for raw materials, balancing cost-efficiency with superior quality.",
  },
  {
    title: "Bulk Manufacturing",
    imgSrc: "https://www.goughecon.com/wp-content/uploads/2020/07/Bulk-material-handling.jpg",
    description:
      "Scale your production seamlessly with trusted factories capable of meeting high-volume demands.",
  },
  {
    title: "Quality Assurance",
    imgSrc: "https://t3.ftcdn.net/jpg/07/46/63/26/360_F_746632634_QMYh51wSdBV56O3JTqTNJAG6IjZ4RsA4.jpg",
    description:
      "Comprehensive product inspections and testing ensure your goods meet specifications and industry standards.",
  },
  {
    title: "Packaging Solutions",
    imgSrc: "https://media.licdn.com/dms/image/D4D12AQGOwLfUmqHQ_A/article-cover_image-shrink_720_1280/0/1714211643596?e=2147483647&v=beta&t=lBm1THmLNmzyCEkcrpGhwIESWGee2iuT5MxFLtwVgUw",
    description:
      "Custom packaging designs and sourcing services to protect and showcase your products effectively.",
  },
  {
    title: "Logistics and Shipping",
    imgSrc: "https://t4.ftcdn.net/jpg/01/38/06/79/360_F_138067977_NokvPQ7EUAnvspOOBVCR6yeAJyBG1LnE.jpg",
    description:
      "End-to-end coordination of product transportation, ensuring timely and secure delivery to your destination.",
  },
  {
    title: "Dropshipping Setup",
    imgSrc: "https://www.makemyfirm.ae/wp-content/uploads/2023/05/Dropshipping-busienss-in-Dubai.jpg",
    description:
      "Enable direct shipment of products to your customers, reducing storage costs and improving efficiency.",
  },
  {
    title: "Private Labeling",
    imgSrc: "https://www.icagruppen.se/globalassets/5.-innovation/3.-trender-och-innovationer/04.-utveckling-av-egna-markesvaror/emv-19-2-700x350.jpg",
    description:
      "Create a unique brand identity by sourcing products with your customized labels and designs.",
  },
  {
    title: "Market Compliance",
    imgSrc: "https://e5qjgyxhp85.exactdn.com/wp-content/uploads/2023/10/Risk-and-Compliance-Services-2023-Market-Insights-scaled.jpg?strip=all&lossy=1&ssl=1",
    description:
      "We ensure your products meet regulatory requirements and certifications for target markets.",
  },
  {
    title: "After-Sales Support",
    imgSrc: "https://st4.depositphotos.com/2572561/22248/i/450/depositphotos_222485774-stock-photo-financier-works-personal-computer-showing.jpg",
    description:
      "Assistance with returns, warranties, and supplier negotiations to maintain customer satisfaction.",
  },
  {
    title: "Cost Negotiation",
    imgSrc: "https://crossculture2go.com/wp-content/uploads/2021/10/negotiations-in-France.png",
    description:
      "Leverage our expertise to secure the best prices and terms from suppliers.",
  },
  {
    title: "Sustainable Sourcing",
    imgSrc: "https://cdn.prod.website-files.com/6238bcab9402836401c2a1da/64747791fea71f3468e50e3d_recycled-plastic-waste-environment-pollution-garb-2022-06-21-20-09-40-utc%20(1).webp",
    description:
      "Partner with eco-conscious suppliers and adopt sustainable practices to align with modern consumer values.",
  },
  {
    title: "Vendor Relationship Management",
    imgSrc: "https://www.agilus.ai/wp-content/uploads/2022/04/Vendor-Relationship-Management-Best-Practices.jpg",
    description:
      "Ongoing management of supplier relationships to optimize communication and performance.",
  },
];

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {productSourcingSections.map((section, index) => (
          <div key={index} className="carousel-slide">
            <img
              src={section.imgSrc}
              alt={section.title}
              className="carousel-image"
            />
            <div className="overlay">
              <h2 className="carousel-title">{section.title}</h2>
              <p className="carousel-description">{section.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
