// src/App.js
import React from "react"; 
import Carousel from "./Carousel";
import Navbar from "./Navbar";
import "./App.css";
import About from "./About";
import ProductCategories from "./ProductCategories";
import Testimonials from "./Testimonials";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import Catalog from "./Catalog";



function App() {
  return (
    <div style={{overflowX: "hidden"}}>
      <Navbar />
      <Carousel />
      <About />
      <ProductCategories />
      <Catalog />
      {/* <Testimonials /> */}
      <ContactForm />
      <Footer />
      {/* Other components like Carousel, About, Footer go here */}
    </div>
  );
}

export default App;

