// src/components/ContactForm.js
import React, { useState } from "react";
import "./ContactForm.css"; // Importing styles

const ContactForm = () => {
  // State to manage form fields and success message
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false); // To track form submission

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from reloading the page
    setSubmitted(true);  // Set success message to show after submission

    // Clear form fields after submission
    setName("");
    setEmail("");
    setMessage("");

    // Set timeout to hide the success message after 3 seconds
    setTimeout(() => {
      setSubmitted(false); // Hide success message
    }, 3000); // 3000ms = 3 seconds
  };

  return (
    <section className="contact-form-container" id="contact">
      <h2>Contact Us</h2>

      {/* Show success message if submitted */}
      {submitted && (
        <div className="success-message">
          <p>Thank you for reaching out! We will get back to you soon.</p>
        </div>
      )}

      <div className="contact-form-wrapper">
        {/* Contact Form Section */}
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Your Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Your Email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Your Message"
            />
          </div>

          <button type="submit" className="submit-btn">
            Send Message
          </button>
        </form>

        {/* Contact Image Section */}
        <div className="contact-image">
          <img
            src="https://www.pegstaff.com/wp-content/uploads/2023/09/customer-service-representative-at-work-2022-12-16-14-38-18-utc-scaled.jpg" // Placeholder image, replace with actual contact image URL
            alt="Contact Us"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
